import React, { useState, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import * as s from './RegisterPlanStyled';

import Swal from 'sweetalert2';

import moment from 'moment';
import 'moment/locale/ko';

import Slider from '../../../components/slider/Slider';
// import { DayPicker } from 'react-dates';
import DayPickerStyle from '../../../styles/react-dates-styles';
import DayPicker from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import queryString from 'query-string';

import { comnApi } from '../../../api/api-comn';
import { useRecoilValue, useRecoilState } from 'recoil';
import * as recoilItem from '../../../util/recoilitem';

import YearlyCreatePlan from './yearlyPlan';
import MonthlyCreatePlan from './monthlyPlan';
import WeeklyCreatePlan from './weeklyPlan';
import DailyCreatePlan from './dailyPlan';

import Header from '../../../components/header';
import Footer from '../../../components/footer';

interface RegisterPlanProps extends RouteComponentProps {}

const RegisterPlanContainer = (props: RegisterPlanProps) => {
  const token = useRecoilValue(recoilItem.token);
  const [isLoading, setIsLoading] = useRecoilState(recoilItem.isLoading);

  const [type, setType] = useState('yearly');
  const [ageAuths, setAgeAuths] = useState<any>([]);
  const [icons, setIcons] = useState<any>([]);
  const [option, setOption] = useState<{
    age: string | null;
    year: number;
    month: number;
    week: number;
    day: number;
  }>({
    age: 'AUTH0101',
    year: moment().year(),
    month: Number(moment().format('M')),
    week: moment().weeks() - moment().add(0, 'month').startOf('month').weeks() + 1,
    day: moment().date(),
  });
  const yearList = [
    new Date().getFullYear() - 2,
    new Date().getFullYear() - 1,
    new Date().getFullYear(),
    new Date().getFullYear() + 1,
    new Date().getFullYear() + 2,
    // new Date().getFullYear() + 3,
  ].reverse();
  const [dayList, setDayList] = useState<any>([]);

  useEffect(() => {
    setOption({
      age: 'AUTH0101',
      year: moment().year(),
      month: Number(moment().format('M')),
      week: moment().weeks() - moment().add(0, 'month').startOf('month').weeks() + 1,
      day: moment().date(),
    });
    setType(
      queryString.parse(props.location.search).type
        ? String(queryString.parse(props.location.search).type)
        : 'yearly',
    );
  }, [props.location]);

  const getTypeText = (type: string) => {
    switch (type) {
      case 'yearly':
        return '연간';
      case 'monthly':
        return '월간';
      case 'weekly':
        return '주간';
      case 'daily':
        return '일일';
      default:
        return '';
    }
  };

  const onSetType = (type: string) => {
    setType(type);
  };

  const setAge = (age: string) => {
    setOption({
      ...option,
      age: age,
    });
  };

  const setYear = (year: number) => {
    setOption({
      ...option,
      year: year,
    });
  };

  const setMonth = (month: number) => {
    setType('monthly');
    setOption({
      ...option,
      month: month,
    });
  };
  const setWeek = (week: number) => {
    setType('weekly');
    setOption({
      ...option,
      week: week,
    });
  };
  const setDay = (month: number, day: number, idx: number) => {
    setType('daily');
    setOption({
      ...option,
      month: month,
      week: ~~(idx / 7) + 1,
      // moment([option.year, option.month - 1, day]).weeks() -
      // moment([option.year, option.month - 1, 1])
      //   .add(0, 'month')
      //   .startOf('month')
      //   .weeks() +
      // 1,
      day: day,
    });
  };

  const fetchData = async () => {
    try {
      setIsLoading(true);
      await comnApi.getCd('AUTH01').then((res) => {
        if (res.data.rsltCd === '00') {
          let ageAuthList: any = {};
          res.data.ComnCd.subCdList.map(
            (item: { comnCd: string; comnCdNm: string; inggCd: string; viewOrder: number }) => {
              ageAuthList[item.comnCd] = item;
            },
          );
          setAgeAuths(ageAuthList);
        }
      });
      await comnApi.getCd('ICON01').then((res) => {
        if (res.data.rsltCd === '00') {
          let iconList: any = {};
          res.data.ComnCd.subCdList.map(
            (item: { comnCd: string; comnCdNm: string; inggCd: string; viewOrder: number }) => {
              iconList[item.comnCd] = item;
            },
          );
          setIcons(iconList);
        }
      });
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  };

  const makeDays = () => {
    let days: number[] = [];
    if (
      option.month === 1 ||
      option.month === 3 ||
      option.month === 5 ||
      option.month === 7 ||
      option.month === 8 ||
      option.month === 10 ||
      option.month === 12
    ) {
      days = [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25,
        26, 27, 28, 29, 30, 31,
      ];
    } else if (
      option.month === 4 ||
      option.month === 6 ||
      option.month === 9 ||
      option.month === 11
    ) {
      days = [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25,
        26, 27, 28, 29, 30,
      ];
    } else if (option.month === 2 && option.year % 4 === 0) {
      if (option.year % 400 !== 0 && option.year % 100 === 0) {
        days = [
          1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25,
          26, 27, 28,
        ];
      } else {
        days = [
          1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25,
          26, 27, 28, 29,
        ];
      }
    } else if (option.month === 2) {
      days = [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25,
        26, 27, 28,
      ];
    }

    if (new Date(option.year, option.month - 1, days[0]).getDay() === 0) {
      // Sunday
      days = [...days];
    } else if (new Date(option.year, option.month - 1, days[0]).getDay() === 1) {
      // Monday
      days = [0, ...days];
    } else if (new Date(option.year, option.month - 1, days[0]).getDay() === 2) {
      // Tuesday
      days = [0, 0, ...days];
    } else if (new Date(option.year, option.month - 1, days[0]).getDay() === 3) {
      // Wednesday
      days = [0, 0, 0, ...days];
    } else if (new Date(option.year, option.month - 1, days[0]).getDay() === 4) {
      // Thursday
      days = days.slice(3);
    } else if (new Date(option.year, option.month - 1, days[0]).getDay() === 5) {
      // Friday
      days = days.slice(2);
    } else if (new Date(option.year, option.month - 1, days[0]).getDay() === 6) {
      // Saturday
      days = days.slice(1);
    }
    let loopCount = 35 - days.length;
    for (let i = 0; i < loopCount; i++) {
      days.push(0);
    }

    setDayList(days);
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    makeDays();
  }, [option]);

  return (
    <>
      <Header {...props} />
      <s.Container>
        <DayPickerStyle />
        <s.PlanningHeader>
          <s.PlanningHeaderTitle>계획안 작성</s.PlanningHeaderTitle>
          <s.PlanningHeaderRightArea>
            <s.PlanningHeaderRightText>
              <s.PlanningHeaderRightIcon src="/static/img/home_icon.jpg" />
              {'HOME > 교육계획안 > ' + getTypeText(type) + ' 글작성'}
            </s.PlanningHeaderRightText>
          </s.PlanningHeaderRightArea>
        </s.PlanningHeader>
        <s.PlanningTabArea>
          <s.PlanningTab active={type === 'yearly'} onClick={() => onSetType('yearly')}>
            연간 교육계획안
          </s.PlanningTab>
          <s.PlanningTab active={type === 'monthly'} onClick={() => onSetType('monthly')}>
            월간 교육계획안
          </s.PlanningTab>
          <s.PlanningTab active={type === 'weekly'} onClick={() => onSetType('weekly')}>
            주간 교육계획안
          </s.PlanningTab>
          <s.PlanningTab active={type === 'daily'} onClick={() => onSetType('daily')}>
            일일 교육계획안
          </s.PlanningTab>
        </s.PlanningTabArea>
        {/* <s.FullSelectArea>
          <s.FullSelectLabel>학년도 선택</s.FullSelectLabel>
          <s.FullSelectDiv></s.FullSelectDiv>
          <s.FullSelectForm>
            <Slider
              sildeList={yearList.map((year, idx) => (
                <s.YearItem
                  key={'year' + idx}
                  active={year === option.year}
                  onClick={() => setYear(year)}
                >
                  {year}년
                </s.YearItem>
              ))}
              prevBtn={
                <s.SliderArrowIcon>
                  <s.SliderArrowIconImg src="/static/img/slide-6.png" />
                </s.SliderArrowIcon>
              }
              nextBtn={
                <s.SliderArrowIcon>
                  <s.SliderArrowIconImg src="/static/img/slide-5.png" />
                </s.SliderArrowIcon>
              }
              slideSize={20}
            />
          </s.FullSelectForm>
          <s.FullSelectLabel>연령 선택</s.FullSelectLabel>
          <s.FullSelectDiv></s.FullSelectDiv>
          <s.FullSelectForm100>
            <Slider
              sildeList={
                ageAuths &&
                Object.entries(ageAuths).map(([key, value]) => {
                  return (
                    <s.AgeItem
                      key={'age' + value}
                      active={key === option.age}
                      onClick={() => setAge(key)}
                    >
                      만 {ageAuths[key].comnCdNm}
                    </s.AgeItem>
                  );
                })
              }
              slideSize={20}
            />
          </s.FullSelectForm100>
        </s.FullSelectArea>
        {type !== 'yearly' ? (
          <s.FullSelectArea>
            <s.HalfSelectLeftArea>
              <s.FullSelectLabel>월 선택</s.FullSelectLabel>
              <s.FullSelectForm100>
                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((month, idx) => (
                  <s.MonthItem
                    key={'month' + idx}
                    active={month === option.month}
                    onClick={() => setMonth(month)}
                  >
                    {month}월
                  </s.MonthItem>
                ))}
              </s.FullSelectForm100>
              <s.FullSelectLabel>주간 선택</s.FullSelectLabel>
              <s.FullSelectForm100>
                <s.SelectLine>
                  {[1, 2].map((week, idx) => (
                    <s.WeekItem
                      key={'week' + week}
                      active={(type === 'weekly' || type === 'daily') && week === option.week}
                      onClick={() => setWeek(week)}
                    >
                      {week}주차
                    </s.WeekItem>
                  ))}
                </s.SelectLine>
                <s.SelectLine>
                  {[3, 4, 5].map((week, idx) => (
                    <s.WeekItem
                      key={'week' + week}
                      active={(type === 'weekly' || type === 'daily') && week === option.week}
                      onClick={() => setWeek(week)}
                    >
                      {week}주차
                    </s.WeekItem>
                  ))}
                </s.SelectLine>
              </s.FullSelectForm100>
            </s.HalfSelectLeftArea>
            <s.HalfSelectRightArea>
              <DayPicker
                showOutsideDays={true}
                weekdayElement={({ weekday, className, localeUtils, locale }) => (
                  <s.DayPickerWeekday className={className}>
                    {localeUtils.formatWeekdayShort(weekday, 'en')[0]}
                  </s.DayPickerWeekday>
                )}
                captionElement={({ date, localeUtils }) => (
                  <s.DayPickerCaption>{moment(date).format('M')}월</s.DayPickerCaption>
                )}
                selectedDays={
                  type === 'daily'
                    ? [moment([option.year, option.month - 1, option.day]).toDate()]
                    : []
                }
                month={moment([option.year, option.month - 1, option.day]).toDate()}
                onDayClick={(day) => {
                  setDay(Number(moment(day).format('M')), Number(moment(day).format('D')));
                }}
                modifiers={{
                  weeks:
                    type === 'weekly'
                      ? [
                          moment(
                            String(option.year) +
                              String(
                                option.week +
                                  moment([option.year, option.month - 1, 1]).weeks() -
                                  2,
                              ),
                            'YYYYWW',
                          )
                            .add(1, 'd')
                            .toDate(),
                          moment(
                            String(option.year) +
                              String(
                                option.week +
                                  moment([option.year, option.month - 1, 1]).weeks() -
                                  2,
                              ),
                            'YYYYWW',
                          )
                            .add(2, 'd')
                            .toDate(),
                          moment(
                            String(option.year) +
                              String(
                                option.week +
                                  moment([option.year, option.month - 1, 1]).weeks() -
                                  2,
                              ),
                            'YYYYWW',
                          )
                            .add(3, 'd')
                            .toDate(),
                        ]
                      : [],
                  weeksstart:
                    type === 'weekly'
                      ? [
                          moment(
                            String(option.year) +
                              String(
                                option.week +
                                  moment([option.year, option.month - 1, 1]).weeks() -
                                  2,
                              ),
                            'YYYYWW',
                          ).toDate(),
                        ]
                      : [],
                  weeksend:
                    type === 'weekly'
                      ? [
                          moment(
                            String(option.year) +
                              String(
                                option.week +
                                  moment([option.year, option.month - 1, 1]).weeks() -
                                  2,
                              ),
                            'YYYYWW',
                          )
                            .add(4, 'd')
                            .toDate(),
                        ]
                      : [],
                }}
              />
            </s.HalfSelectRightArea>
          </s.FullSelectArea>
        ) : null} */}
        <s.SelectArea>
          <s.SelectTableHead
            style={{ borderWidth: '1px', borderColor: '#DEDEDE', borderStyle: 'solid' }}
          >
            <s.SelectTableHeadItem style={{ width: '92px', borderRight: '1px solid #DEDEDE' }}>
              연령 선택
            </s.SelectTableHeadItem>
            <s.SelectTableHeadItem style={{ width: '122px', borderRight: '1px solid #DEDEDE' }}>
              학년도 선택
            </s.SelectTableHeadItem>
            <s.SelectTableHeadItem style={{ width: '303px', borderRight: '1px solid #DEDEDE' }}>
              월 선택
            </s.SelectTableHeadItem>
            <s.SelectTableHeadItem style={{ width: '92px', borderRight: '1px solid #DEDEDE' }}>
              주 선택
            </s.SelectTableHeadItem>
            <s.SelectTableHeadItem style={{ width: '482px', flexDirection: 'column' }}>
              <s.SelectTableHeadItem
                style={{ height: '41px', width: '100%', borderBottom: '1px solid #DEDEDE' }}
              >
                {option.year}년 {option.month}월
              </s.SelectTableHeadItem>
              <s.SelectTableHeadItem
                style={{ height: '33px', border: '0px', flexDirection: 'row' }}
              >
                <s.SelectTableHeadItem style={{ width: '69px', borderLeft: '1px solid #DEDEDE' }}>
                  일
                </s.SelectTableHeadItem>
                <s.SelectTableHeadItem style={{ width: '69px', borderLeft: '1px solid #DEDEDE' }}>
                  월
                </s.SelectTableHeadItem>
                <s.SelectTableHeadItem style={{ width: '69px', borderLeft: '1px solid #DEDEDE' }}>
                  화
                </s.SelectTableHeadItem>
                <s.SelectTableHeadItem style={{ width: '69px', borderLeft: '1px solid #DEDEDE' }}>
                  수
                </s.SelectTableHeadItem>
                <s.SelectTableHeadItem style={{ width: '69px', borderLeft: '1px solid #DEDEDE' }}>
                  목
                </s.SelectTableHeadItem>
                <s.SelectTableHeadItem style={{ width: '69px', borderLeft: '1px solid #DEDEDE' }}>
                  금
                </s.SelectTableHeadItem>
                <s.SelectTableHeadItem
                  style={{
                    width: '70px',
                    borderLeft: '1px solid #DEDEDE',
                    borderRight: '1px solid #DEDEDE',
                  }}
                >
                  토
                </s.SelectTableHeadItem>
              </s.SelectTableHeadItem>
            </s.SelectTableHeadItem>
          </s.SelectTableHead>
          <s.SelectTableBody>
            <s.SelectTableBodyColumn style={{ width: '92px' }}>
              {Object.entries(ageAuths).map(([key, value]) => {
                return (
                  <s.SelectTableBodyColumnItem
                    key={'age' + value}
                    active={key === option.age}
                    onClick={() => setAge(key)}
                    style={{ width: '100%' }}
                  >
                    만 {ageAuths[key].comnCdNm}
                  </s.SelectTableBodyColumnItem>
                );
              })}
            </s.SelectTableBodyColumn>
            <s.SelectTableBodyColumn style={{ width: '121px' }}>
              {yearList.reverse().map((year, idx) => {
                return (
                  <s.SelectTableBodyColumnItem
                    key={'year' + idx}
                    active={year === option.year}
                    onClick={() => {
                      setYear(year);
                    }}
                    style={{ width: '121px' }}
                  >
                    {year}년
                  </s.SelectTableBodyColumnItem>
                );
              })}
            </s.SelectTableBodyColumn>
            <s.SelectTableBodyColumn style={{ width: '302px' }}>
              <s.SelectTableBodyColumn style={{ flexDirection: 'row' }}>
                {[3, 4, 5].map((month, idx) => {
                  return (
                    <s.SelectTableBodyColumnItem
                      key={'month' + (month - 1)}
                      active={month === option.month && type !== 'yearly'}
                      onClick={() => {
                        setMonth(month);
                      }}
                      style={{ width: '101px' }}
                    >
                      {month}월
                    </s.SelectTableBodyColumnItem>
                  );
                })}
              </s.SelectTableBodyColumn>
              <s.SelectTableBodyColumn style={{ flexDirection: 'row' }}>
                {[6, 7, 8].map((month, idx) => {
                  return (
                    <s.SelectTableBodyColumnItem
                      key={'month' + (month - 1)}
                      active={month === option.month && type !== 'yearly'}
                      onClick={() => {
                        setMonth(month);
                      }}
                      style={{ width: '101px' }}
                    >
                      {month}월
                    </s.SelectTableBodyColumnItem>
                  );
                })}
              </s.SelectTableBodyColumn>
              <s.SelectTableBodyColumn style={{ flexDirection: 'row' }}>
                {[9, 10, 11].map((month, idx) => {
                  return (
                    <s.SelectTableBodyColumnItem
                      key={'month' + (month - 1)}
                      active={month === option.month && type !== 'yearly'}
                      onClick={() => {
                        setMonth(month);
                      }}
                      style={{ width: '101px' }}
                    >
                      {month}월
                    </s.SelectTableBodyColumnItem>
                  );
                })}
              </s.SelectTableBodyColumn>
              <s.SelectTableBodyColumn style={{ flexDirection: 'row' }}>
                {[12, 1, 2].map((month, idx) => {
                  return (
                    <s.SelectTableBodyColumnItem
                      key={'month' + (month - 1)}
                      active={month === option.month && type !== 'yearly'}
                      onClick={() => {
                        setMonth(month);
                      }}
                      style={{ width: '101px' }}
                    >
                      {month}월
                    </s.SelectTableBodyColumnItem>
                  );
                })}
              </s.SelectTableBodyColumn>
              <s.SelectTableBodyColumn style={{ flexDirection: 'row' }}>
                {[0, 0, 0].map((month, idx) => {
                  return (
                    <s.SelectTableBodyColumnItem
                      active={false}
                      style={{ width: '101px', cursor: 'default' }}
                    ></s.SelectTableBodyColumnItem>
                  );
                })}
              </s.SelectTableBodyColumn>
            </s.SelectTableBodyColumn>
            <s.SelectTableBodyColumn style={{ width: '92px' }}>
              {[1, 2, 3, 4, 5].map((week, idx) =>
                week < 5 ? (
                  <s.SelectTableBodyColumnItem
                    style={{ width: '92px' }}
                    key={'week' + week}
                    active={week === option.week && type !== 'yearly' && type !== 'monthly'}
                    onClick={() => setWeek(week)}
                  >
                    {week}주차
                  </s.SelectTableBodyColumnItem>
                ) : new Date(option.year, option.month, 0).getDay() < 3 ||
                  dayList.filter((item: number) => item !== 0).length < 29 ? (
                  <s.SelectTableBodyColumnItem
                    style={{ width: '92px', backgroundColor: '#BEBEBE', cursor: 'default' }}
                    key={'week' + week}
                    active={week === option.week && type !== 'yearly' && type !== 'monthly'}
                  >
                    {week}주차
                  </s.SelectTableBodyColumnItem>
                ) : (
                  <s.SelectTableBodyColumnItem
                    style={{ width: '92px' }}
                    key={'week' + week}
                    active={week === option.week && type !== 'yearly' && type !== 'monthly'}
                    onClick={() => setWeek(week)}
                  >
                    {week}주차
                  </s.SelectTableBodyColumnItem>
                ),
              )}
            </s.SelectTableBodyColumn>
            <s.SelectTableBodyColumn style={{ width: '482px' }}>
              <s.SelectTableDateTable>
                {dayList.map((date: number, idx: number) => {
                  if (~~idx % 7 === 6) {
                    if (date === 0) {
                      return (
                        <s.SelectTableDateCell
                          saturday={false}
                          sunday={false}
                          style={{ borderRight: '1px solid #DEDEDE', width: '70px' }}
                          inWeek={false}
                          hoverable={false}
                          active={false}
                        ></s.SelectTableDateCell>
                      );
                    } else {
                      return (
                        <s.SelectTableDateCell
                          inWeek={
                            new Date(option.year, option.month - 1, date).getDay() !== 0 &&
                            new Date(option.year, option.month - 1, date).getDay() !== 6 &&
                            type === 'weekly' &&
                            ~~(idx / 7) + 1 === option.week
                          }
                          hoverable={true}
                          active={type === 'daily' && date === option.day}
                          style={{ borderRight: '1px solid #DEDEDE', width: '70px' }}
                          saturday={new Date(option.year, option.month - 1, date).getDay() === 6}
                          sunday={new Date(option.year, option.month - 1, date).getDay() === 0}
                          onClick={() => {
                            setDay(option.month, date, idx);
                          }}
                        >
                          {date}
                        </s.SelectTableDateCell>
                      );
                    }
                  } else {
                    if (date === 0) {
                      return (
                        <s.SelectTableDateCell
                          saturday={false}
                          sunday={false}
                          inWeek={false}
                          hoverable={false}
                          active={false}
                        ></s.SelectTableDateCell>
                      );
                    } else {
                      return (
                        <s.SelectTableDateCell
                          inWeek={
                            new Date(option.year, option.month - 1, date).getDay() !== 0 &&
                            new Date(option.year, option.month - 1, date).getDay() !== 6 &&
                            type === 'weekly' &&
                            ~~(idx / 7) + 1 === option.week
                          }
                          hoverable={true}
                          active={type === 'daily' && date === option.day}
                          saturday={new Date(option.year, option.month - 1, date).getDay() === 6}
                          sunday={new Date(option.year, option.month - 1, date).getDay() === 0}
                          onClick={() => {
                            setDay(option.month, date, idx);
                          }}
                        >
                          {date}
                        </s.SelectTableDateCell>
                      );
                    }
                  }
                })}
              </s.SelectTableDateTable>
              {/* <DayPicker
                showOutsideDays={false}
                weekdayElement={({ weekday, className, localeUtils, locale }) => (
                  <s.DayPickerWeekday className={className}>
                    {localeUtils.formatWeekdayShort(weekday, 'en')[0]}
                  </s.DayPickerWeekday>
                )}
                captionElement={({ date, localeUtils }) => (
                  null
                )}
                selectedDays={
                  type === 'daily'
                    ? [moment([option.year, option.month - 1, option.day]).toDate()]
                    : []
                }
                month={moment([option.year, option.month - 1, option.day]).toDate()}
                disabledDays={disabledDays}
                onDayClick={(day) => {
                  setDay(Number(moment(day).format('M')), Number(moment(day).format('D')));
                }}
                modifiers={{
                  weeks:
                    type === 'weekly'
                      ? [
                          moment(
                            String(option.year) +
                              String(
                                option.week +
                                  moment([option.year, option.month - 1, 1]).weeks() -
                                  2,
                              ),
                            'YYYYWW',
                          )
                            .add(1, 'd')
                            .toDate(),
                          moment(
                            String(option.year) +
                              String(
                                option.week +
                                  moment([option.year, option.month - 1, 1]).weeks() -
                                  2,
                              ),
                            'YYYYWW',
                          )
                            .add(2, 'd')
                            .toDate(),
                          moment(
                            String(option.year) +
                              String(
                                option.week +
                                  moment([option.year, option.month - 1, 1]).weeks() -
                                  2,
                              ),
                            'YYYYWW',
                          )
                            .add(3, 'd')
                            .toDate(),
                        ]
                      : [],
                  weeksstart:
                    type === 'weekly'
                      ? [
                          moment(
                            String(option.year) +
                              String(
                                option.week +
                                  moment([option.year, option.month - 1, 1]).weeks() -
                                  2,
                              ),
                            'YYYYWW',
                          ).toDate(),
                        ]
                      : [],
                  weeksend:
                    type === 'weekly'
                      ? [
                          moment(
                            String(option.year) +
                              String(
                                option.week +
                                  moment([option.year, option.month - 1, 1]).weeks() -
                                  2,
                              ),
                            'YYYYWW',
                          )
                            .add(4, 'd')
                            .toDate(),
                        ]
                      : [],
                }}
              /> */}
            </s.SelectTableBodyColumn>
          </s.SelectTableBody>
        </s.SelectArea>

        {option.age !== null && type === 'monthly' ? (
          <MonthlyCreatePlan option={option} ageAuths={ageAuths} yearList={yearList} />
        ) : option.age !== null && type === 'weekly' ? (
          <WeeklyCreatePlan option={option} ageAuths={ageAuths} yearList={yearList} />
        ) : option.age !== null && type === 'yearly' ? (
          <YearlyCreatePlan
            {...props}
            type={type}
            setType={setType}
            option={option}
            setOption={setOption}
            ageAuths={ageAuths}
          />
        ) : option.age !== null && type === 'daily' ? (
          <DailyCreatePlan option={option} ageAuths={ageAuths}></DailyCreatePlan>
        ) : (
          <s.PlanNotFoundText>연령을 선택해주세요.</s.PlanNotFoundText>
        )}
      </s.Container>
      <Footer {...props} />
    </>
  );
};

export default RegisterPlanContainer;
